import { UserStatusEnum, userStatusList } from '@/enums/UserStatusEnum';
import { UserRoleEnum, userRoleList } from '@/enums/UserRoleEnum';
import { regionList } from '@/enums/RegionEnum';
import { countryList } from '@/enums/CountryEnum';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userStatusList: userStatusList.filter(
        (item) => item.value !== UserStatusEnum.ALL.value
      ),
      regionList,
      countryList,
    };
  },
  computed: {
    activeUserRole() {
      return this.$store.state.AppActiveUser.role;
    },
    userRoleList() {
      const roleFilter = (role) => {
        const allowedGlobal = [
          UserRoleEnum.REGION.value,
          UserRoleEnum.COUNTRY.value,
          UserRoleEnum.KAM.value,
        ];
        const allowedRegion = [
          UserRoleEnum.COUNTRY.value,
          UserRoleEnum.KAM.value,
        ];
        const allowedCountry = [UserRoleEnum.KAM.value];
        const allowedKam = [];

        // GLOBAL
        if (this.activeUserRole === UserRoleEnum.GLOBAL.value) {
          return allowedGlobal.includes(role.value);
        }

        // REGION
        if (this.activeUserRole === UserRoleEnum.REGION.value) {
          return allowedRegion.includes(role.value);
        }

        // COUNTRY
        if (this.activeUserRole === UserRoleEnum.COUNTRY.value) {
          return allowedCountry.includes(role.value);
        }

        // KAM
        if (this.activeUserRole === UserRoleEnum.KAM.value) {
          return allowedKam.includes(role.value);
        }

        // SYSADMIN
        return role.value !== UserRoleEnum.ALL.value;
      };

      return userRoleList.filter(roleFilter);
    },
  },
};
